import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { rpg1 } from '../../assets/images'
import { ContactUs, FormCard } from '../../components'
import './style.css'

const Form = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    window.onresize = () => setWindowWidth(window.innerWidth)
    return (
        <div className='product-form'>
            <div className="product-form-rpg1">
                <img src={rpg1} alt="rpg" width={(windowWidth * 90) / 100} />
            </div>
            <div className='product-form-reg-card'>
                <div className='product-form-title'>Enterprise Vault.cloud Form</div>
                <div className=''>
                    <FormCard >
                        <Outlet />
                    </FormCard>
                </div>
            </div>
            <ContactUs />
        </div>
    )
}

export default Form