import React, { useCallback, useState } from 'react';
import './style.css'
import { AiFillCaretDown } from 'react-icons/ai'
import { cart, cartBlue, failureBlue, failure, fileMinus, license, licenseBlue, truck, truckBlue, Logo, fileMinusBlue } from '../../assets/images';
import { BootstrapTable, SelectComponent } from '../../components';
import { useNavigate } from 'react-router-dom';
import { getOrderList } from '../../api';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import { logout, userUpdate } from '../../redux/UserSlice';
import { useEffect } from 'react';
import { isLoggedIn } from '../../assets/helper';
import { OverlayTrigger, Popover, Spinner } from 'react-bootstrap';
const TABS = { orders: "OrderList", provision: "Provision", decommission: 'Decommission', license: 'License Mgmt', failure: 'Failure' }
const Orders = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector(state => state.auth?.userData)
    const reloadUserInfo = useCallback(
        async () => {
            const tokenData = await Auth.currentSession()
            const userData = await Auth.currentUserInfo();
            console.log(userData)
            const authData = {
                token: tokenData.idToken.jwtToken,
                ...userData.attributes,
                username: userData.username,
                groups: tokenData.accessToken?.payload["cognito:groups"],
                accessJwtToken: tokenData.accessToken?.jwtToken,
            };
            dispatch(userUpdate(authData))
        },
        [dispatch],
    )

    useEffect(() => {
        if (isLoggedIn() && !user) reloadUserInfo()
        if (!isLoggedIn()) navigate('/')
    }, [navigate, reloadUserInfo])
    const [selectedTab, setSelectedTab] = useState(TABS.orders)
    const [data, setData] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [pageCount, setPageCount] = React.useState(0)
    const fetchIdRef = React.useRef(0)
    const fetchData = React.useCallback(async ({ pageSize, pageIndex }) => {
        // This will get called when the table needs new data
        // You could fetch your data from literally anywhere,
        // even a server. But for this example, we'll just fake it.

        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current

        // Set the loading state
        setLoading(true)

        // We'll even set a delay to simulate a server here
        // setTimeout(() => {
        //     // Only update the data if this is the latest fetch
        //     if (fetchId === fetchIdRef.current) {
        //         const startRow = pageSize * pageIndex
        //         const endRow = startRow + pageSize
        //         setData(serverData.slice(startRow, endRow))
        //         // Your server could send back total page count.
        //         // For now we'll just fake it, too
        //         setPageCount(Math.ceil(serverData.length / pageSize))
        //         setLoading(false)
        //     }
        // }, 1000)
        const res = await getOrderList()
        setData(res.data)
        console.log("res.data.data", res.data)
        setLoading(false)
    }, [])
    if (!isLoggedIn()) return <Spinner />
    return (
        <div className='order-root'>
            <div className="d-flex flex-column flex-shrink-0 p-3 sidebar" style={{ width: 280 }}>
                <img src={Logo} alt='logo' className='sidebar-logo' onClick={() => navigate('/', { replace: true })} />
                <ul className="nav nav-pills flex-column mb-auto item-list">
                    <li className={`nav-item ${selectedTab === TABS.orders && 'active'}`} onClick={() => setSelectedTab(TABS.orders)}>
                        <img src={TABS.orders === selectedTab ? cartBlue : cart} alt='cart' className='nav-item-img' />
                        Orders
                    </li>
                    <li className={`nav-item ${selectedTab === TABS.provision && 'active'}`} onClick={() => { return; setSelectedTab(TABS.provision) }} >
                        <img src={TABS.provision === selectedTab ? truckBlue : truck} alt='truck' className='nav-item-img' />
                        Provision
                    </li>
                    <li className={`nav-item ${selectedTab === TABS.decommission && 'active'}`} onClick={() => { return; setSelectedTab(TABS.decommission) }} >
                        <img src={TABS.decommission === selectedTab ? fileMinusBlue : fileMinus} alt='fileMinus' className='nav-item-img' />
                        Decommission
                    </li>
                    <li className={`nav-item ${selectedTab === TABS.license && 'active'}`} onClick={() => { return; setSelectedTab(TABS.license) }} >
                        <img src={TABS.license === selectedTab ? licenseBlue : license} alt='license' className='nav-item-img' />
                        License Mgmt
                    </li>
                    <li className={`nav-item ${selectedTab === TABS.failure && 'active'}`} onClick={() => { return; setSelectedTab(TABS.failure) }}>
                        <img src={TABS.failure === selectedTab ? failureBlue : failure} alt='failure' className='nav-item-img' />
                        Failure
                    </li>
                </ul>
            </div>
            <div className='order-main-screen'>
                <div className='heading'>
                    <div className='title-search'>
                        <div className="title">{selectedTab}</div>
                        <input type={'text'} className='search-box' placeholder='Search' />
                    </div>
                    <div className="user">
                        <div className='name'>User Name</div>
                        <div className='pic'>U</div>
                        <OverlayTrigger trigger="click" placement="bottom" overlay={<Popover id="popover-basic">
                            <Popover.Body>
                                <span style={{ cursor: 'pointer' }} onClick={async () => {
                                    await Auth.signOut()
                                    dispatch(logout()); navigate('/')
                                }}>Logout</span>
                            </Popover.Body>
                        </Popover>}>
                            <div className='caret'><AiFillCaretDown /></div>
                        </OverlayTrigger>
                    </div>
                </div>
                <div className='body'>
                    {selectedTab === TABS.orders && <OrderList data={data} fetchData={fetchData} loading={loading} pageCount={pageCount} />}
                    {selectedTab === TABS.provision && <Provision data={data} fetchData={fetchData} loading={loading} pageCount={pageCount} />}
                    {selectedTab === TABS.decommission && <Decommission data={data} fetchData={fetchData} loading={loading} pageCount={pageCount} />}
                    {selectedTab === TABS.license && <LicenseMgmt data={data} fetchData={fetchData} loading={loading} pageCount={pageCount} />}
                    {selectedTab === TABS.failure && <Failure data={data} fetchData={fetchData} loading={loading} pageCount={pageCount} />}
                </div>
            </div>
        </div>
    )
}

// const popover = (

// );

const OrderList = ({ data, fetchData, loading, pageCount }) => {
    return (<>
        <BootstrapTable data={data} fetchData={fetchData} loading={loading} pageCount={pageCount} />
    </>)
}

const Provision = ({ data, fetchData, loading, pageCount }) => {
    return (<>
        <BootstrapTable data={data} fetchData={fetchData} loading={loading} pageCount={pageCount} />
    </>)
}
const Decommission = ({ data, fetchData, loading, pageCount }) => {
    return (<>
        <BootstrapTable data={data} fetchData={fetchData} loading={loading} pageCount={pageCount} />
    </>)
}
const LicenseMgmt = ({ data, fetchData, loading, pageCount }) => {
    return (<>
        <BootstrapTable data={data} fetchData={fetchData} loading={loading} pageCount={pageCount} />
    </>)
}
const Failure = ({ data, fetchData, loading, pageCount }) => {
    return (<>
        <BootstrapTable data={data} fetchData={fetchData} loading={loading} pageCount={pageCount} />
    </>)
}


const range = len => {
    const arr = []
    for (let i = 0; i < len; i++) {
        arr.push(i)
    }
    return arr
}

const newPerson = () => {
    const statusChance = Math.random()
    return {
        orderId: Math.floor(Math.random() * 30),
        customerName: "John Doe",
        product: 'Show',
        from: 'Open',
        status:
            statusChance > 0.66
                ? 'Submitted'
                : statusChance > 0.33
                    ? 'Customization in Progress'
                    : 'Provisioning In progress',
    }
}

function makeData(...lens) {
    const makeDataLevel = (depth = 0) => {
        const len = lens[depth]
        return range(len).map(d => {
            return {
                ...newPerson(),
                subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined,
            }
        })
    }
    return makeDataLevel()
}


export default Orders