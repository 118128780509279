import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Accordion, Button, Col, Row, Spinner } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getSkuOptions } from '../../api'
import { formStatus, formTypes } from '../../assets/helper'
import { SelectSkuRow } from '../../components'
import { updateFormStatus } from '../../redux/FormSlice'
import { setSelectedSku, setSkuList } from '../../redux/SkuSlice'
import './style.css'

const SelectSku = () => {
    const selectedRow = useSelector(state => state.form.skuSelect)
    const navigate = useNavigate()
    const { id } = useParams()
    const dispatch = useDispatch()
    const { error, data, isLoading, } = useQuery('getSkuOptions', { queryFn: () => getSkuOptions({ productId: id }) })
    const [skuselecterror, setSkuselecterror] = useState("")
    const [skuerrorshow, setSkuerrorshow] = useState(false)
    console.log(data);
    if (error) {
        alert(error.message)
    }

    useEffect(() => {
        data && dispatch(setSkuList(data.data))
    }, [data, dispatch])
    if (isLoading) return <div className='loader-container'><Spinner /></div>

    const handleskuSelectNextStep = () => {

        if (selectedRow.selectedProduct === "") {
            setSkuselecterror("Please select any one of the following services")
            setSkuerrorshow(true)
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            return
        }

        var newArray = data.data.filter(function (ele) {
            return ele.sku === selectedRow.selectedProduct
        })
        console.log("Requested Array")
        console.log(newArray)
        const selectedSku = newArray[0]
        console.log(selectedSku.hasOwnProperty('options'));

        if (selectedSku.hasOwnProperty('options') === true) {
            if (selectedRow.selectedProduct === "") {
                setSkuselecterror("Please select any one of the following services")
                setSkuerrorshow(true)
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                return
            }
            else if (selectedRow.selectedProduct !== "" && selectedRow.selectedSubProduct === "") {
                setSkuselecterror("Please select any one of the subservices in the selected service")
                setSkuerrorshow(true)
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                return
            }
            else {
                dispatch(updateFormStatus({ type: formTypes.ServicesSelection, status: formStatus.done }));
                dispatch(updateFormStatus({ type: formTypes.TechnicalContactInformation, status: formStatus.current }));
                dispatch(setSelectedSku({ sku: selectedRow.selectedProduct, option: selectedRow.selectedSubProduct }))
                // navigate('/products/form/contact-info', { replace: true })
                navigate('/products/form/contact-info')
            }
        }
        else {
            if (selectedRow.selectedProduct === "") {
                setSkuselecterror("Please select any one of the following services")
                setSkuerrorshow(true)
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                return
            } else {
                dispatch(updateFormStatus({ type: formTypes.ServicesSelection, status: formStatus.done }));
                dispatch(updateFormStatus({ type: formTypes.TechnicalContactInformation, status: formStatus.current }));
                dispatch(setSelectedSku({ sku: selectedRow.selectedProduct, option: selectedRow.selectedSubProduct }))
                // navigate('/products/form/contact-info', { replace: true })
                navigate('/products/form/contact-info')
            }
        }
    }
    return (
        <>
            <Row className='select-sku-heading'>
                {skuerrorshow ? (<div className="Error-Message"> {skuselecterror}</div>) : <></>}
                <Col md={1} xl={1} className="sku-select-heading"></Col>
                <Col md={3} xl={3} className="sku-select-heading">Service</Col>
                <Col md={6} xl={6} className="sku-select-heading">Descriptions</Col>
                <Col md={2} xl={2} className="sku-select-heading">Meter</Col>
            </Row>
            <Row className="justify-content-md-center"><Col md={{ span: 10, offset: 2 }} xl={{ span: 10, offset: 2 }} className='line'></Col></Row>
            <Accordion>
                {data?.data.map(service =>
                    <SelectSkuRow key={service.sku} description={service.description} meter={service.meter} service={service.service} value={service.sku} selected={selectedRow === service.sku} extras={service.options} setSkuerrorshow={setSkuerrorshow} />)}
            </Accordion>
            <Row>
                <Col md={12} className="d-flex justify-content-center align-item-center mt-3">
                    <span className="back-btn" onClick={() => { navigate(-1) }}>Go back</span>
                    <Button className="next-step-btn" onClick={handleskuSelectNextStep}>Next Step</Button>
                </Col>
            </Row>
        </>
    )
}

export default SelectSku