import React, { useCallback } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { countryList } from '../../assets/constants'
import { InputComponent, SelectComponent } from '../../components'
import './style.css'
import { useDispatch, useSelector } from 'react-redux'
import { setTrialQuestions, updateFormStatus } from '../../redux/FormSlice'
import { formStatus, formTypes } from '../../assets/helper'
import { useState } from 'react'

const TrialQuestionnaire = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const form = useSelector(state => state.form.trialQuestions)
    const { id } = useParams()
    const [validatione, setValidatione] = useState("")
    const [errorshow, setErrorshow] = useState(false)
    console.log(form)

    const handelFormChange = useCallback((e) => {
        setErrorshow(false)
        const { name, value } = e.target;
        dispatch(setTrialQuestions([name, value]))
    }, [dispatch])

    const handleNextStep = useCallback(() => {
        if (form.objective === "" || form.timeline === "" || form.total_user === "" || form.address_1 === "" || form.state === "" || form.country === "" || form.city === "" || form.postcode === "") {
            setValidatione("All fields are mandatory")
            setErrorshow(true)
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        }
        else {
            dispatch(updateFormStatus({ type: formTypes.ServicesSelection, status: formStatus.current }));
            dispatch(updateFormStatus({ type: formTypes.TrialQuestionnaire, status: formStatus.done }));
            // navigate('/products/form/select-sku/' + id, { replace: true })
            navigate('/products/form/select-sku/' + id)
        }
    }, [dispatch, form])

    return (
        <>
            <Row >
                {errorshow ? (<div className="Error-Message"> {validatione}</div>) : <></>}
                <Col md={6} xs={12} className='trial-input-group'>
                    <div className='trial-container-title mb-3'>What is your Objective for this engagement?</div>
                    <InputComponent name={'objective'} placeholder="Type your Objective" type={'text'} label="Type your Objective" onChange={handelFormChange} value={form.objective} />

                </Col>
                <Col md={6} xs={12} className='trial-input-group'>
                    <div className='trial-container-title mb-3'>Desired timeline for the trial to start?</div>
                    <InputComponent name={'timeline'} placeholder="Type your timeline" type={"date"} label="Type your timeline" onChange={handelFormChange} value={form.timeline}
                    />
                </Col>
            </Row>
            <Row >
                <Col md={6} xs={12} className='trial-input-group'>
                    <div className='trial-container-title mb-3'>How many total user for the Trial?</div>
                    <InputComponent name={'total_user'} placeholder="Type total user" type={'text'} label="Type total user" onChange={handelFormChange} value={form.total_user}
                    />
                </Col>
            </Row>
            <Row >
                <Col md={6} xs={12} className='trial-input-group'>
                    <div className='trial-container-title mb-3'>Location</div>
                    {/* <InputComponent name={'objective'} placeholder="Type total user" type={'text'} label="Type total user" /> */}
                    <SelectComponent items={countryList} name="country" label={"Country"} onChange={handelFormChange} value={form.country || "United States of America"} />
                </Col>
                <Col md={6} xs={12} className='trial-input-group'>
                    <div className='trial-container-title mb-3'>&nbsp;</div>
                    <InputComponent name={'address_1'} placeholder="Address line 1" type={'text'} label="Customer Address line 1" onChange={handelFormChange} value={form.address_1}
                    />
                </Col>
            </Row>
            <Row >
                <Col md={6} xs={12} className='trial-input-group'>
                    <InputComponent name={'address_2'} placeholder="Address line 2 (optional)" type={'text'} label="Customer Address line 2 (optional)" onChange={handelFormChange} value={form.address_2}
                    />
                </Col>
                <Col md={6} xs={12} className='trial-input-group'>
                    <InputComponent name={'state'} placeholder="State" type={'text'} label="State" onChange={handelFormChange} value={form.state}
                    />
                </Col>
            </Row>
            <Row >
                <Col md={6} xs={12} className='trial-input-group'>
                    <InputComponent name={'city'} placeholder="City" type={'text'} label="City" onChange={handelFormChange} value={form.city}
                    />
                </Col>
                <Col md={6} xs={12} className='trial-input-group '>
                    <InputComponent name={'postcode'} placeholder="Zip / postcode" type={'text'} label="Zip / Postcode" onChange={handelFormChange} value={form.postcode} />
                </Col>
            </Row>
            <Row>
                <Col md={12} className="d-flex justify-content-center mt-3">
                    <Button className="next-step-btn" onClick={handleNextStep}>Next Step</Button>
                </Col>
            </Row>
        </>
    )
}

export default TrialQuestionnaire