import React, { useCallback, useState } from 'react'
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import './style.css'
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux';
import { resetForm, updateFormStatus } from '../../redux/FormSlice';
import { formStatus, formTypes } from '../../assets/helper';
import { postOrder } from '../../api';

const FinalConfirmation = () => {
    const navigate = useNavigate()
    // const [show, setShow] = useState(false)
    const dispatch = useDispatch()
    const finalForm = useSelector(state => state.form)
    const sku = useSelector(state => state.sku)
    const user = useSelector(state => state.user)
    const [loading, setLoading] = useState(false)
    const finalOrderSubmit = useCallback(async () => {
        setLoading(true)
        let form = { ...finalForm }
        delete form.formStatus
        const response = await postOrder(form)
        if (response) {
            Swal.fire({
                icon: 'success',
                title: 'Order Submitted Successfully',
                showConfirmButton: false,
                timer: 1500
            })
            navigate('/', { replace: true });
            dispatch(updateFormStatus({ type: formTypes.FinalConfirmation, status: formStatus.done }));
            dispatch(resetForm())
        }
    }, [dispatch, finalForm, navigate])
    return (<Container>
        {finalForm.formStatus['Trial Questionnaire'] === formStatus.done && <Row><TrialQuestions data={finalForm.trialQuestions} /></Row>}
        <Row><SkuSelection data={sku} /></Row>
        <Row><TechnicalContactInfo data={finalForm.contactInfo} /></Row>
        {/* <Row><InvoicingContact data={user.userData} /></Row> */}
        <Row><InvoicingContact data={finalForm.trialQuestions} /></Row>
        <Row>
            <Col md={12} className="d-flex justify-content-center align-item-center mt-3">
                <span className="back-btn" onClick={() => navigate(-1, { replace: true })}>Go back</span>
                <Button className="next-step-btn" onClick={finalOrderSubmit}>{loading ? <Spinner /> : "Submit"}</Button>
            </Col>
        </Row>
    </Container>
    )
}

const TrialQuestions = ({ data }) => {
    return <>
        <Row className='contact-info-title'><Col md={12} xl={12}>Trial Questionnaire</Col></Row>
        {/* <Row className='contact-info-sub-title'>
            <Col md={12} xl={12}>This resource will be copied on initial communications sent by the implementation Project Manager
            </Col>
        </Row> */}
        <Row className='contact-info-section'>
            <Col md={6} xl={6}>What is your Objective for this engagement:</Col>
            <Col md={6} xl={6}>{data.objective}</Col>
        </Row>
        <Row className='contact-info-section'>
            <Col md={6} xl={6}>Desired timeline for the trial to start:</Col>
            <Col md={6} xl={6}>{data.timeline}</Col>
        </Row>
        <Row className='contact-info-section'>
            <Col md={6} xl={6}>How many total user for the Trail:</Col>
            <Col md={6} xl={6}>{data.total_user}</Col>
        </Row>
        {/* <Row><Col md={6} xl={6}>Location</Col></Row>
        <Row className='contact-info-section'>
            <Col md={6} xl={6}>Country:</Col>
            <Col md={6} xl={6}>{data.country}</Col>
        </Row>
        <Row className='contact-info-section'>
            <Col md={6} xl={6}>Address 1:</Col>
            <Col md={6} xl={6}>{data.address_1}</Col>
        </Row>
        <Row className='contact-info-section'>
            <Col md={6} xl={6}>Address 2:</Col>
            <Col md={6} xl={6}>{data.address_2}</Col>
        </Row>
        <Row className='contact-info-section'>
            <Col md={6} xl={6}>State:</Col>
            <Col md={6} xl={6}>{data.state}</Col>
        </Row>
        <Row className='contact-info-section'>
            <Col md={6} xl={6}>City:</Col>
            <Col md={6} xl={6}>{data.city}</Col>
        </Row>
        <Row className='contact-info-section'>
            <Col md={6} xl={6}>Zip/Postcode:</Col>
            <Col md={6} xl={6}>{data.postcode}</Col>
        </Row> */}
    </>
}

const SkuSelection = ({ data }) => {

    return <>
        <Row className='contact-info-title'><Col md={12} xl={12}>Selected Service</Col></Row>
        {/* <Row className='contact-info-sub-title'>
            <Col md={12} xl={12}>This resource will receive the Administrator credentials for Enterprise Vault.cloud
            </Col>
        </Row> */}
        {data.selectedSku.sku && <Row className='contact-info-section'>
            <Col md={6} xl={6}>Selected Sku:</Col>
            <Col md={6} xl={6}>{data.selectedSku.sku.service}</Col>
        </Row>}
        {data.selectedSku.option && <Row className='contact-info-section'>
            <Col md={6} xl={6}>Selected Sku Option:</Col>
            <Col md={6} xl={6}>{data.selectedSku.option.text}</Col>
        </Row>}
    </>
}

const TechnicalContactInfo = ({ data }) => (
    <>
        <Row className='contact-info-title'><Col md={12} xl={12}>Technical Information</Col></Row>
        {/* <Row className='contact-info-sub-title'>
            <Col md={12} xl={12}></Col>
        </Row> */}
        <Row className='contact-info-section'>
            <Col md={6} xl={6}>Administrator Name:</Col>
            <Col md={6} xl={6}>{data.name}</Col>
        </Row>
        <Row className='contact-info-section'>
            <Col md={6} xl={6}>Administrator Email:</Col>
            <Col md={6} xl={6}>{data.email}</Col>
        </Row>
        <Row className='contact-info-section'>
            <Col md={6} xl={6}>Data Center:</Col>
            <Col md={6} xl={6}>{data.dataCenter}</Col>
        </Row>
        <Row className='contact-info-section'>
            <Col md={6} xl={6}>Domain:</Col>
            <Col md={6} xl={6}>{data.domain}</Col>
        </Row>
        <Row className='contact-info-section'>
            <Col md={6} xl={6}>Mail Platforms:</Col>
            <Col md={6} xl={6}>{data.mailPlatform?.join(", ")}</Col>
        </Row>
        <Row className='contact-info-section'>
            <Col md={6} xl={6}>Legacy Data import:</Col>
            <Col md={6} xl={6}>{data.legacyDataImport}</Col>
        </Row>
    </>
)
const InvoicingContact = ({ data }) => (
    <>
        <Row className='contact-info-title'><Col md={12} xl={12}>Invoicing Details</Col></Row>
        {/* <Row className='contact-info-sub-title'>
            <Col md={12} xl={12}></Col>
        </Row> */}
        {/* <Row className='contact-info-section'>
            <Col md={6} xl={6}>Name:</Col>
            <Col md={6} xl={6}>{data.name}</Col>
        </Row>
        <Row className='contact-info-section'>
            <Col md={6} xl={6}>Telephone number:</Col>
            <Col md={6} xl={6}>+1 999 999 9999</Col>
        </Row>
        <Row className='contact-info-section'>
            <Col md={6} xl={6}>Email address:</Col>
            <Col md={6} xl={6}>{data.email}</Col>
        </Row> */}
        {/* <Row className='contact-info-section'>
            <Col md={6} xl={6}>Company VAT number (if applicable):</Col>
            <Col md={6} xl={6}>Devis.Mouradian@veritas.com</Col>
        </Row> */}
        {/* <Row className='contact-info-section'>
            <Col md={6} xl={6}>Invoicing Address:</Col>
            <Col md={6} xl={6}>417 OAKBEND DR., SUITE 180
                LEWISVILLE, TX75067
                United States
            </Col>
        </Row> */}
        <Row className='contact-info-section'>
            <Col md={6} xl={6}>Invoicing Address:</Col>
            <Col md={6} xl={6}>{data.address_1} {data.address_2} {data.city} {data.state} {data.postcode} {data.country}
            </Col>
        </Row>
    </>
)

export default FinalConfirmation
